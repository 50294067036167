html,
body,
#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  flex: 1 0 auto;
}

h3,
h4,
h5 {
  margin: 0.75rem 0;
}

h2,
.donate-button,
.footer span {
  text-transform: uppercase;
  font-weight: 700;
  color: #158ec5;
  animation-name: textclip;
  animation-duration: 30s;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  text-align: center;
}

@keyframes textclip {
  0% {
    color: #158ec5;
  }
  25% {
    color: #ff9e7d;
  }
  50% {
    color: #8f69ff;
  }
  75% {
    color: #ff698e;
  }
  100% {
    color: #158ec5;
  }
}

.footer {
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.footer span {
  display: inline-block;
  text-transform: none;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 600;
}

.footer .logo {
  width: 30px;
  margin-right: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.popup p {
  width: 50%;
  padding: 10px;
  background: linear-gradient(
    to top left,
    #158ec5 0%,
    #8f69ff 40%,
    #ff698e 100%
  );
  background-clip: text;
}

.popup-buttons-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
}

input,
textarea {
  border: none;
  padding: 0;
}

.outline {
  outline: 1px dotted #158ec5;
}

.user-panel {
  width: 200px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.user-panel div,
.user-panel .regular-button {
  width: 180px;
  margin-right: 5px;
}

/*Grid structure*/

.resume-body {
  max-width: 1100px;
  background-color: #fff;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  padding-top: 25px;
  box-shadow: 0 0 10px 5px #f9f9f9;
}

.resume-body .name,
.resume-body .summary {
  padding-right: 25px;
}

div.summary .text {
  margin-bottom: 0;
}

.flex-column-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.basics-container {
  grid-column: 2 / 4;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 2;
}

.name div:first-child {
  font-size: 1.2rem;
}

.photo {
  grid-column: 1;
  grid-row: 1 / 2;
  align-self: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo img {
  display: block;
}

.contacts {
  display: flex;
  flex-direction: column;
}

.languages {
  margin-bottom: 1rem;
  position: relative;
}

.aside-left {
  grid-column: 1;
  grid-row: 2;
  padding-left: 28px;
}

.resume-body div.aside-right {
  grid-column: 2 / 4;
  grid-row: 2;
  padding: 0;
}

.experience {
  grid-column: 2 / 4;
  grid-row: 3;
  position: relative;
}

.education {
  grid-column: 2 / 4;
  grid-row: 4;
  position: relative;
}

.contacts-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}

.contacts-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contacts-container svg {
  margin-right: 5px;
}

.tech {
  width: 100%;
  padding: 0;
  background-color: transparent;
  resize: none;
  font-family: inherit;
  font-size: inherit;
}

.experience h4 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.nested-container {
  padding: 5px 28px 5px 0;
  position: relative;
  min-height: 50px;
}

.empty-container {
  height: 20px;
}

div.autosize-input {
  display: inline-block;
  margin-top: 3px;
}

div.autosize-input input {
  min-width: 8px;
}

button.donate-button {
  display: block;
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(
    to top left,
    #158ec5 0%,
    #8f69ff 40%,
    #ff698e 100%
  );
  border-image-slice: 1;
  cursor: pointer;
  line-height: 30px;
  height: 35px;
  width: 180px;
  font-size: inherit;
  font-weight: bold;
  box-sizing: border-box;
  background: none;
  text-transform: none;
  margin-bottom: 5px;
}

button.donate-button:hover {
  box-shadow: 5px 5px 5px rgba(143, 105, 255, 0.1);
}

.regular-button {
  display: block;
  cursor: pointer;
  line-height: 32px;
  height: 35px;
  width: 180px;
  font-size: inherit;
  font-weight: bold;
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  background-color: none;
  border-image-source: linear-gradient(
    to top left,
    #158ec5 0%,
    #8f69ff 40%,
    #ff698e 100%
  );
  border-image-slice: 1;
  background: linear-gradient(
    to top left,
    #158ec5 0%,
    #8f69ff 40%,
    #ff698e 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 5px;
  text-decoration: none;
}

.regular-button:hover {
  background: transparent
    linear-gradient(to top left, #158ec5 0%, #8f69ff 40%, #ff698e 100%);
  -webkit-text-fill-color: #fff;
}

.add-button {
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 23px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(to top left, #158ec5 0%, #8f69ff 100%);
  border-image-slice: 1;
  text-align: center;
}

.add-button:hover {
  background: linear-gradient(to top left, #158ec5 0%, #8f69ff 100%);
}

.add-button:before {
  content: "\2715";
  display: block;
  background: linear-gradient(to top left, #158ec5 0%, #8f69ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: rotate(45deg);
}

.add-button:hover:before {
  -webkit-text-fill-color: #fff;
}

.delete-button {
  position: absolute;
  top: 2px;
  right: 0;
  width: 23px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(to top left, #ff698e 0%, orange 100%);
  border-image-slice: 1;
  text-align: center;
}

.delete-button:hover {
  background: linear-gradient(to top left, #ff698e 0%, orange 100%);
}

.delete-button:before {
  content: "\2715";
  background: linear-gradient(to top left, #ff698e 0%, orange 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.delete-button:hover:before {
  -webkit-text-fill-color: #fff;
}

.up-button {
  position: absolute;
  top: 36%;
  margin-top: 1px;
  right: 7px;
  width: 5px;
  height: 5px;
  border-color: black;
  border-style: solid;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(-135deg);
}

.up-button:hover {
  border-color: orange;
  cursor: pointer;
}

.down-button {
  position: absolute;
  bottom: 36%;
  margin-bottom: 1px;
  right: 7px;
  width: 5px;
  height: 5px;
  cursor: pointer;
  border-color: black;
  border-style: solid;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(45deg);
}

.down-button:hover {
  border-color: #158ec5;
}

div.langs {
  margin-bottom: 1rem;
}

#file-input,
#file-upload {
  display: none;
}

.photo svg {
  cursor: pointer;
}

.photo .user-pic {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit: cover;
}

.text-bold {
  font-weight: 600;
  font-size: inherit;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .outline {
    outline: none;
  }

  .resume-body {
    box-shadow: none;
    overflow: hidden;
  }

  input::after,
  textarea::after {
    page-break-inside: avoid;
  }

  .nested-container {
    min-height: 20px;
  }

  @page {
    size: auto;
    margin: 20px 5px 29px 5px;
  }

  .wrapper,
  .App.Safari .name,
  .App.Safari .flex-row-container,
  .App.Safari .flex-column-container,
  .App.Safari .basics-container,
  .App.Safari .aside-right,
  .App.Safari .aside-left,
  .App.Safari .date-container,
  .App.Safari .contacts-container,
  .App.Safari .contacts,
  .App.Safari .name,
  .App.Safari div.photo {
    display: block;
  }
  .App.Safari .contacts-container {
    margin: 0;
  }

  .App.Safari h3,
  .App.Safari h4,
  .App.Safari h5 {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 1080px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
  .user-panel {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
}

@media only screen and (max-width: 820px) {
  .resume-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    gap: 0;
  }

  div.aside-left {
    padding: 0;
  }

  .flex-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flex-row-container div {
    margin-right: 10px;
  }

  .flex-column-container {
    flex: 1 2 50%;
    order: 1;
  }

  .photo {
    flex: 1 2 40%;
    order: 1;
  }

  .basics-container {
    order: 2;
  }

  .summary {
    order: 3;
  }

  .aside-right {
    order: 4;
  }

  .aside-left {
    order: 5;
  }
}

.date-container span {
  margin-right: 2px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.input-sizer {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  margin-top: 3px;
}

.input-sizer.text {
  display: grid;
  width: 100%;
  align-items: stretch;
}
.input-sizer.text textarea,
.input-sizer.text::after {
  width: 100%;
  grid-area: 2 / 1;
  overflow: hidden;
}

.input-sizer::after,
.input-sizer textarea {
  width: auto;
  min-width: 1em;
  grid-area: 1 / 2;
  font: inherit;
  padding: 0;
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  border: none;
}

.input-sizer::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}

input:focus,
textarea:focus {
  outline: 1px dotted #ff9e7d;
}
